<template>
  <div class="optimize-time-cards">
    <div
      class="optimize-time-card background_contrast dark_blue--text d-flex flex-column"
      v-for="(card, index) in cards"
      :key="index"
    >
      <div class="icon primary">
        <prosk-icon :icon="card.icon" />
      </div>
      <h5 class="title">{{ card.title }}</h5>
      <p class="text text_tertiary--text">{{ card.text }}</p>
      <router-link
        class="secondary-prosk text font-weight-medium mt-auto mb-0 d-block hide-sm"
        :to="{ name: 'social.subscription-page' }"
      >
        {{ $t('accessNow') }}
        <prosk-icon class="ml-2" icon="arrow-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ProskIcon from '@/icons/ProskIcon.vue';

export default {
  name: 'OptimizeTimeCards',
  components: {
    ProskIcon
  },
  data () {
    return {
      cards: []
    }
  },
  created () {
    for (let i = 0; i < 4; i++) {
      this.cards.push({
        title: this.$t(`optimizeTimeCards.${i}.title`),
        text: this.$t(`optimizeTimeCards.${i}.text`),
        icon: this.$t(`optimizeTimeCards.${i}.icon`)
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.optimize-time-cards {
  display: flex;
  flex-basis: 1;
  justify-content: center;
  margin: 64px auto;
  gap: 24px;

  .optimize-time-card {
    padding: 24px;

    .title {
      font-size: 20px;
      font-weight: 500;
      margin: 8px 0;
    }

    .text {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 20px;
    }
  }

  .icon {
    display: block;
    border-radius: 10px;
    margin-bottom: 64px;
    height: 48px;
    padding: 12px;
    width: 48px;

    ::v-deep(.prosk-icon) {
      height: 24px;
      width: 24px;
    }

    ::v-deep(svg path) {
      stroke: #fff !important;
    }
  }
}

@media (max-width: 1000px) {
  .optimize-time-cards {
    flex-wrap: wrap;

    .optimize-time-card {
        flex: calc(50% - 12px) 0 0;
    }
  }
}

@media (max-width: 768px) {
  .hide-sm {
    display: none !important;
  }

  .optimize-time-cards {
    flex-wrap: wrap;
    flex-direction: column;

    .optimize-time-card {
        flex: 100% 0 0;
    }
  }
}
</style>
