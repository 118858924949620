<!-- for now this page is showed only for BR -->
<template>
  <v-main class="container-prosker top-container">
    <div
      class="wide-banner text_contrast_to_background p-96 pb-0 background-hero"
      :class="localeClass"
      :style="{
        backgroundImage: `url(${require('@/assets/images/page-img/vector_home_woman_banner.jpg')})`,
      }"
    >
      <v-row class="d-flex">
        <v-col
          class="prosker-text-section d-flex flex-column mt-md-8 p-0"
          col="12"
          md="6"
        >
          <h1
            class="text-h4 text-md-h2 mb-4 secondary--text font-weight-bold line-height mt-md-9"
            v-html="$t('grow_business')"
          ></h1>
          <span class="text-h6 main_text_color--text">{{ $t('platform')  }}</span>
          <div class="mt-8 gap-2 d-flex flex-column flex-md-row">
            <general-button
              :action="goToSubscriptions"
              :loading="false"
              :message="$t('begin')"
              size="medium"
              cssClasses="flex-grow-xs-1"
              id="home-start-btn-1"
            />
            <general-button
              :action="goToBuy"
              :loading="false"
              :message="$t('wantToHire')"
              size="medium"
              cssClasses="flex-grow-xs-1"
              outlined
              id="home-hire-btn-1"
            />
          </div>
        </v-col>
      </v-row>
      <img src="@/assets/images/page-img/vector_home_woman_banner-mobile.jpg" class="hero-side-image d-md-none d-block" />
    </div>

    <div class="advantages wide-banner text_contrast_to_background text-center">
      <div class="advantages-header">
        <div class="small-title font-weight-medium">
          {{ $t('communityAdvantages') }}
        </div>
        <h2 class="font-size-36 secondary--text font-weight-medium">
          {{ $t('communityAdvantagesTitle') }}
        </h2>
        <p class="font-size-20 dark_blue--text">
          {{ $t('communityAdvantagesText') }}
        </p>
      </div>
      <advantages-grid />
      <general-button
        :action="goToSubscriptions"
        :loading="false"
        :message="$t('takeCommunityAdvantages')"
        size="medium"
        cssClasses="flex-grow-xs-1 w-100 w-md-auto"
      />
    </div>

    <div class="wide-banner text_contrast_to_background visibility-banner">
      <v-parallax
        dark
        :src="require('@/assets/images/page-img/purple-swirl-bg.jpg')"
        class="border-radius-medium"
      >
        <div class="content">
          <h2 class="white--text font-size-36 font-weight-medium">
            {{ $t('moreVisiblity') }}
          </h2>
          <p class="text white--text">
            {{ $t('moreVisiblityText') }}
          </p>
          <div class="actions">
            <general-button
              :action="goToSubscriptions"
              :loading="false"
              :message="$t('begin')"
              size="medium"
              cssClasses="flex-grow-xs-1 white-btn"
              outlined
            />
            <general-button
              :action="goToBuy"
              :loading="false"
              :message="$t('wantToHire')"
              size="medium"
              cssClasses="flex-grow-xs-1 white-btn"
              outlined
            />
          </div>
        </div>
        <img :src="i18nImage('iphone-mockup.png')" class="mobile-mock" />
        <img :src="i18nImage('notification-queue.png')" class="notification-queue d-none d-md-block" />
      </v-parallax>
    </div>

    <div class="gain-visibility-section">
      <v-row class="pt-16 pl-3 pr-3 pr-lg-0 pl-lg-0">
        <v-col  md="6" cols="12">
          <div class="small-title font-weight-medium">
            {{ $t('gainVisibility') }}
          </div>
          <h2 class="font-size-36 secondary--text font-weight-medium">
            {{ $t('easyCreateAccount') }}
          </h2>
          <p class="font-size-20 dark_blue--text">
            {{ $t('easyCreateAccountText') }}
          </p>
        </v-col>
        <v-col  md="6" cols="12">
          <div class="d-flex flex-column">
            <div class="flex-top-container">
              <div class="icon-square">
                <prosk-icon icon="user-square" />
              </div>
              <div>
                <h3 class="font-size-20 font-weight-medium">1. {{ $t('customiseProfileFree') }}</h3>
                <p class="font-size-16">{{ $t('customiseProfileFreeText') }}</p>
              </div>
            </div>
            <div class="flex-top-container">
              <div class="icon-square">
                <prosk-icon icon="zap" />
              </div>
              <div>
                <h3 class="font-size-20 font-weight-medium">1. {{ $t('boostYourSales') }}</h3>
                <p class="font-size-16">{{ $t('boostYourSalesText') }}</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <video-banner
      class="wide-banner text_contrast_to_background video-br"
      :video-url="videoUrl"
      :is-youtube="true"
      :showText="false"
    >
      <div class="mt-8 gap-2 d-flex flex-column flex-md-row justify-content-center">
        <general-button
          :action="goToSubscriptions"
          :loading="false"
          :message="$t('begin')"
          size="medium"
          cssClasses="flex-grow-xs-1"
          id="home-start-btn-1"
        />
        <general-button
          :action="goToBuy"
          :loading="false"
          :message="$t('wantToHire')"
          size="medium"
          cssClasses="flex-grow-xs-1"
          outlined
          id="home-hire-btn-1"
        />
      </div>
    </video-banner>

    <div class="advantages wide-banner text_contrast_to_background">
      <div class="advantages-header">
        <div class="small-title font-weight-medium">
          {{ $t('optimizeTime') }}
        </div>
        <h2 class="font-size-36 secondary--text font-weight-medium">
          {{ $t('optimizeTimeTitle') }}
        </h2>
        <p class="font-size-20 dark_blue--text">
          {{ $t('optimizeTimeText') }}
        </p>
      </div>

      <optimize-time-cards />
    </div>

    <div class="wide-banner pb-96 text_contrast_to_background">
      <TestimonialsWide />
    </div>

    <div class="wide-banner p-mobile-64 p-96 pb-96">
      <v-row align="start">
        <v-col cols="12" md="8">
          <h2 class="font-size-36 secondary--text font-weight-medium mb-5">
            {{ $t('newsLetterHomeTitle') }}
          </h2>
          <p class="font-size-20 dark_blue--text">
            {{ $t('newsLetterHomeText') }}
          </p>
        </v-col>
        <v-col tag="form" cols="12" md="4" class="d-flex newsletter-form">
          <custom-text-field
            :placeholder="$t('registerYourEmail')"
            :hide-details="errorEmail.length === 0"
            :error-count="errorEmail.length"
            :error-messages="errorEmail"
            v-model="email"
            class="w-100"
          />
          <general-button
            :action="subscribeNewsletter"
            :loading="false"
            :message="$t('subscribeCta')"
            size="medium"
            class="newsletter-btn"
          />
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>
<script>
import TestimonialsWide from '@/components/socialvue/testimonials/TestimonialsWide';
import { USER_TYPE_CLIENT, USER_TYPE_PROSKER } from '@/misc/constants';
import { mapActions } from 'vuex';
import ProskIcon from '@/icons/ProskIcon.vue';
import userDataMixin from '@/mixins/userDataMixin';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import AdvantagesGrid from '@/components/socialvue/advantagesGrid/AdvantagesGrid.vue';
import VideoBanner from '@/components/socialvue/banners/VideoBanner';
import OptimizeTimeCards from '@/components/socialvue/optimizeTimeCards/OptimizeTimeCards.vue';
import i18ImagesMixin from '@/mixins/i18ImagesMixin';
import subscribeNewsletterMixin from '@/mixins/subscribeNewsletterMixin';
import CustomTextField from '../../components/socialvue/customTextField/CustomTextField.vue';

export default {
  name: 'HomeNew',
  components: {
    CustomTextField,
    TestimonialsWide,
    GeneralButton,
    AdvantagesGrid,
    ProskIcon,
    OptimizeTimeCards,
    VideoBanner
  },
  mixins: [userDataMixin, i18ImagesMixin, subscribeNewsletterMixin],
  data () {
    return {
      videoUrl: process.env.VUE_APP_LOCATION.includes('br') ? 'https://www.youtube.com/embed/0Jl5D0kFptk?si=QXPKLKXujvul98Eu' : 'https://www.youtube.com/embed/jp9rOLDpih0?si=PaKrAZwDgUi8pglD'
    };
  },
  computed: {
    showTestimonials () {
      return process.env.VUE_APP_HIDE_TESTIMONIALS_CARROUSEL !== 'true';
    },
    localeClass () {
      return `locale-${this.$i18n.locale}`;
    }
  },
  created () {
    this.setImportedConstants();
    if (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_CLIENT) {
      this.$router.push({ name: 'social.clients-page' });
    }
  },
  methods: {
    ...mapActions({
      updateSignUpUserType: 'auth/updateSignUpUserType'
    }),
    setImportedConstants () {
        this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
        this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
    },
    goToSubscriptions () {
      this.$router.push({ name: 'social.subscription-page' });
    },
    goToBuy () {
      this.$router.push({ name: 'social.how-works-clients' });
    },
    goToSignUp () {
      this.updateSignUpUserType(this.$options.USER_TYPE_PROSKER);
      this.$router.push({ name: 'auth1.sign-up1' });
    }
  },
  mounted () {
    this.trackUserData('load_content', {
      pageType: 'home'
    });
  }
};
</script>
<style lang="scss" scoped>

.top-container {
  margin-top: 0;
}

.prosker-text-section {
  margin-bottom: 80px;
}

.wide-banner {
  margin: 0 -56px;
  padding: 0 56px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.p-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.pb-96 {
  padding-bottom: 96px;
}

.hero-side-image {
  width: 100%;
}

.small-title {
  color: #228756;
}

.icon-square {
  display: block;
  border: solid 1px #eaecf0;
  border-radius: 10px;
  margin: auto;
  height: 48px;
  padding: 12px;
  width: 48px;

  ::v-deep(.prosk-icon) {
    height: 24px;
    width: 24px;
  }
}

.flex-top-container {
  display: flex;
  align-items: flex-start;
  gap: 14px;

  .icon-square {
    margin-top: 0;
  }
}

.advantages {
  padding-top: 96px;
  padding-bottom: 96px;
}

.visibility-banner {
  padding-bottom: 96px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px 24px 64px 64px;
    width: 50%;

    .text {
      font-size: 20px;
      margin: 20px 0 0;
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 1em;
    margin-top: 48px;

    .white-btn {
      border-color: white;
      color: white!important;
    }
  }

  .notification-queue {
    left: 50%;
    max-width: 320px;
    position: absolute;
  }

  .mobile-mock {
    bottom: 0;
    max-width: 313px;
    position: absolute;
    right: 0;
  }
}

.gain-visibility-section {
  padding-bottom: 160px;
}

.video-br {
  background-image: none !important;

  ::v-deep(.video-container) {
    margin-top: -96px;
  }
}

.newsletter-form {
  align-items: center;
  gap: 16px;

  .newsletter-btn {
    padding: 18px 16px !important;
  }
}

@media screen and (min-width: 961px) {
  .w-md-auto {
    width: auto !important;
  }
}

@media screen and (max-width: 960px) {
  .p-96 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .p-mobile-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .background-hero {
    background-image: none !important;
  }

  .visibility-banner {
    .content {
      padding: 40px 24px;
      width: 100%;
    }

    .mobile-mock {
      position: static;
      margin: auto;
    }

    ::v-deep(.v-parallax) {
      height: auto !important;
    }
  }

  .actions ::v-deep(.v-btn) {
    width: 100%;
  }

  .newsletter-form {
    flex-direction: column;

    > * {
      width: 100%;
      min-height: 48px;
    }
  }

}
</style>
