<template>
  <div class="advantages-grid">
    <div class="advantage-card dark_blue--text" v-for="(card, index) in cards" :key="index">
      <div class="icon">
        <prosk-icon :icon="card.icon" />
      </div>
      <h5 class="title">{{ card.title }}</h5>
      <p class="text">{{ card.text }}</p>
    </div>
  </div>
</template>

<script>
import ProskIcon from '@/icons/ProskIcon.vue';

export default {
  name: 'AdvantagesGrid',
  components: {
    ProskIcon
  },
  data () {
    return {
      cards: []
    }
  },
  created () {
    for (let i = 0; i < 6; i++) {
      this.cards.push({
        title: this.$t(`advantagesCards.${i}.title`),
        text: this.$t(`advantagesCards.${i}.text`),
        icon: this.$t(`advantagesCards.${i}.icon`)
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.advantages-grid {
  display: flex;
  flex-basis: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px auto;
  gap: 32px 64px;
  padding: 0 32px;

  .advantage-card {
    flex: calc((100% - 64px) / 3 - 22px) 0 0;

    .title {
      font-size: 20px;
      font-weight: 500;
      margin: 20px 0 0;
    }

    .text {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }

  .icon {
    display: block;
    border: solid 1px #eaecf0;
    border-radius: 10px;
    margin: auto;
    height: 48px;
    padding: 12px;
    width: 48px;

    ::v-deep(.prosk-icon) {
      height: 24px;
      width: 24px;
    }
  }
}

@media (max-width: 768px) {
  .advantages-grid {
    flex-direction: column;
    gap: 32px 32px;
    padding: 0 16px;

    .advantage-card {
      flex: 100% 0 0;
    }
  }
}
</style>
