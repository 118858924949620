import axios from 'axios';

export default {
  methods: {
    getReviews () {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/reviews?per_page=8&sortBy=created_at,desc`;
      axios
        .get(url)
        .then((response) => {
          this.testimonials = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.showError(error);
          this.loading = false;
        });
    },
    sendDataCarrouselLayerInfo () {
      this.trackUserData('click_carousel', {
        carousel_title: 'testimonials'
      });
    }
  }
};
