<template>
  <v-main class="pt-4">
    <v-row v-if="loading" class="mx-16">
      <v-col v-for="index in 3" :key="index" cols="4">
        <v-skeleton-loader
          v-bind="skeletonAttrs"
          type="list-item-three-line, list-item-three-line, avatar"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mx-md-16" v-else>
      <hooper
        :itemsToShow="1"
        :centerMode="true"
        @slide="onSlideChange"
        class="h-50 prosk-hopper"
        :settings="hooperSettings"
      >
        <slide v-for="(item, n) in testimonials" :key="n">
          <div class="testimonial-wide-card">
            <router-link class="image-wrapper" :to="getUserProfileRoute(item.prosker_user)">
              <v-img
                v-if="item.jobs && item.jobs.client_user.profile_img"
                height="328"
                width="328"
                :src="item.jobs.client_user.profile_img"
              />
              <img
                v-else
                height="328"
                width="328"
                src="@/assets/images/page-img/avatar.png"
              />
            </router-link>
            <div class="pb-10 pt-5">
              <v-rating
                  background-color="warning_prosk"
                  half-increments
                  color="warning_prosk"
                  hover
                  readonly
                  dense
                  v-model="item.rating"
                  class=""
              />
              <p class="secondary--text font-weight-medium font-size-36 m-0 mt-6">{{ item.title }}</p>
              <p class="main_text_color--text font-size-20 m-0 mt-6">{{ item.content }}</p>
              <p class="main_text_color--text font-weight-medium font-size-18 m-0 mt-8">
                -- {{ item.jobs.client_user.first_name }} {{ item.jobs.client_user.last_name }}
              </p>
              <p class="font-size-16 text_tertiary--text m-0">
                {{ getDateFormat(item.created_at) }}
              </p>
            </div>
          </div>
        </slide>
        <hooper-pagination class="pagination" slot="hooper-addons" ></hooper-pagination>
      </hooper>
    </v-row>
  </v-main>
</template>
<script>
import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';
import userDataMixin from '@/mixins/userDataMixin';
import testimonialsMixin from './testimonialsMixin';
import {
  Hooper,
  Slide,
  Pagination as HooperPagination
} from 'hooper';
import 'hooper/dist/hooper.css';
import moment from 'moment';

export default {
  name: 'TestimonialsWide',
  mixins: [goToUserProfile, userDataMixin, testimonialsMixin],
  components: {
    Hooper,
    Slide,
    HooperPagination
  },
  data () {
    return {
      skeletonAttrs: {
        class: 'mb-6',
        elevation: 0
      },
      userSearchedObject: {},
      loading: false,
      testimonials: [],
      currentSlideIndex: 0,
      hooperSettings: {
        infiniteScroll: true,
        centerMode: true,
        // autoPlay: true,
        playSpeed: 3500
        // breakpoints: {
        //   2400: {
        //       itemsToShow: 2
        //   },
        //   1800: {
        //       itemsToShow: 2
        //   },
        //   1500: {
        //       itemsToShow: 3
        //   },
        //   1100: {
        //       itemsToShow: 2.5
        //   },
        //   0: {
        //       itemsToShow: 1.5
        //   }
        // }
      }
    };
  },
  created () {
    this.getReviews();
  },
  methods: {
    getDateFormat (date) {
      moment.locale(process.env.VUE_APP_LOCALE === 'br' ? 'pt-br' : process.env.VUE_APP_LOCALE);
      const serverDate = new Date(date);
      return moment(serverDate).fromNow();
    },
    onSlideChange (payload) {
      this.sendDataCarrouselLayerInfo();
      this.currentSlideIndex = payload.currentSlide;
    }
  }
};
</script>
<style scoped lang="scss">
  .prosk-hopper :deep(.hooper-track) {
    padding: 0;
  }

  .testimonial-wide-card {
    display: flex;
    gap: 160px;
    justify-content: flex-start;

    .image-wrapper ::v-deep(img),
    .image-wrapper ::v-deep(.v-image) {
      border-radius: 16px;
    }
  }

  .pagination {
    ::v-deep(.hooper-indicator) {
      background-color: #eaecf0;
      border-radius: 100%;
      height: 10px;
      margin: 0 8px;
      width: 10px;
    }

    ::v-deep(.hooper-indicator.is-active) {
      background-color: #30f298;
    }
  }

  @media (max-width: 768px) {
    .testimonial-wide-card {
      flex-direction: column;
      gap: 16px;

      .image-wrapper ::v-deep(img),
      .image-wrapper ::v-deep(.v-image) {
        height: 200px;
        width: 200px;
      }
    }
  }
</style>
